<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-body p-0">
        <div
            class="wizard wizard-2"
            id="kt_wizard_v2"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
        >
          <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col-6">

                    <h3>
                      {{ `${blog_id ? 'Update' : 'Add'} ` }} <span v-text="type"></span>
                    </h3>
                    <router-link :to="{name:'dashboard'}">
                      Dashboard
                    </router-link>
                    <router-link :to="{name:'blogs-list',params:{type:type}}">
                      \ <span class="text-capitalize" v-text="type"></span>
                    </router-link>
                    \ {{ `${blog_id ? 'Update' : 'Add'} ` }} <span  v-text="type"></span>
                  </div>
                  <div class="col-6 text-right">
                    <div class="row">
                      <div class="col-12 mt-5 text-right">

                        <v-btn v-if="checkIsAccessible('blog', 'create')"
                            class="text-white btn btn-primary mr-2"
                            depressed
                            :disabled="sizeExceed"
                            @click="createOrUpdate"
                            :loading="isBusy"
                        >Save
                        </v-btn>
                        <v-btn
                            class="btn btn-standard  "
                            depressed
                            @click="redirectTo"
                        >Back
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-8">
                <div class="col-12 form-group">
                  <v-text-field
                      outlined dense
                      type="text"
                      v-model="blog.title"
                      label="Title"
                  ></v-text-field>
                  <span class="text-danger" v-if="$v.blog.title.$error">This information is required</span>
                </div>
                <div class="col-12 form-group" v-if="checkBlogType">
                  <label for class>Content</label>
                  <br/>
                  <ckeditor v-model="blog.content" :config="editorConfig"></ckeditor>

                  <span class="text-danger"
                        v-if="$v.blog.content.$error">This information is required</span>
                </div>
                <div class="col-12 form-group" v-else>
                  <v-text-field
                      outlined dense
                      type="text"
                      v-model="blog.video_url"
                      label="Video Url"
                  ></v-text-field>
                </div>
                <div class="col-12 form-group" v-if="blog.blog_type =='video' && blog.video_url !=''">

                  <div v-html="blog.video_html"></div>
                </div>
                <div class="col-12 form-group">
                  <v-text-field
                      outlined dense
                      v-model="blog.seo_title"
                      label="SEO title"
                  ></v-text-field>
                </div>
                <div class="col-12">
                  <v-file-input
                      outlined dense
                      v-model="blog.author_image"
                      @change="checkSize($event)"
                      label="Author image"
                      prepend-icon=""
                      prepend-inner-icon="mdi-link"
                  ></v-file-input>
                  <span class="text-danger" v-if="sizeExceed">Please upload a file upto 20MB</span>

                </div>
                <div class="col-12 form-group" v-if="blog.author_image_path && blog.author_image_path['thumb']">
                  <img :src="blog.author_image_path['thumb']" alt/>
                </div>
                <div class="col-12 form-group">
                  <v-text-field
                      outlined dense
                      v-model="blog.author_name"
                      label="Author name"
                  ></v-text-field>
                </div>
                <div class="col-12 form-group">
                  <v-text-field
                      outlined dense
                      v-model="blog.seo_keyword"
                      label="Seo keywords"
                  ></v-text-field>
                </div>
                <div class="col-12 form-group">
                  <v-textarea
                      label="SEO content"
                      outlined dense
                      v-model="blog.seo_description"
                  ></v-textarea>
                </div>
              </div>
              <div class="col-4">
                <!--                                <div class="col-12 form-group">-->
                <!--                                    <v-select-->
                <!--                                            label="Type"-->
                <!--                                            outlined dense-->
                <!--                                            :items="blog_types"-->
                <!--                                            item-text="title"-->
                <!--                                            item-value="value"-->
                <!--                                            class="form-control"-->
                <!--                                            v-model="blog.type"-->
                <!--                                            @change="getCategories">-->
                <!--                                    </v-select>-->
                <!--                                </div>-->
                <div class="col-12 form-group" v-if="type=='blog'">
                  <v-select
                      label="Type"
                      outlined dense
                      :items="blog_content_types"
                      item-text="title"
                      item-value="value"
                      class="form-control"
                      v-model="blog.blog_type"
                  >
                  </v-select>
                </div>
                <div class="col-12 form-group">
                  <v-select
                      v-model="selected"
                      :items="categories"
                      label="Categories"
                      multiple
                      outlined
                      dense
                      item-text="title"
                      item-value="id"
                  ></v-select>
                  <!-- <select class="form-control" name id v-model="blog.category_id">
                    <option value>Select Category</option>
                    <option
                      :value="category.id"
                      v-for="(category, index) of categories"
                      :key="index"
                    >{{category.title}}</option>
                  </select>-->
                  <span class="text-danger"
                        v-if="$v.blog.category_id.$error">This information is required</span>
                </div>
                <div class="col-12 form-group">
                  <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="blog.publish_date"
                          label="Publish date"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          dense
                          outlined
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="blog.publish_date"
                                   @input="menu2 = false"></v-date-picker>
                  </v-menu>
                  <span
                      class="text-danger"
                      v-if="$v.blog.publish_date.$error"
                  >This information is required</span>
                </div>
                <div class="col-12 form-group" v-if="blog.type=='event'">
                  <strong class="mt-0">Event start date & time</strong>
                  <datetime class="form-control border-dark"
                            value-zone="Asia/Kathmandu"
                            placeholder="Event Start DateTime"
                            :minute-step="15"
                            format="yyyy-MM-dd hh:mm:ss"
                            use12-hour
                            type="datetime"
                            v-model="blog.event_start">
                    <label for="startDate" slot="before"><i class="fas fa-calendar-alt"></i></label>
                  </datetime>
                  <span>{{ blog.event_start | moment("dddd, MMMM Do YYYY, h:mm:ss a") }}</span>

                </div>
                <div class="col-12 form-group" v-if="blog.type=='event'">
                  <strong class="mt-0">Event end date & time</strong>

                  <datetime class="form-control border-dark"
                            value-zone="Asia/Kathmandu"
                            placeholder="Event End DateTime"
                            :minute-step="15"
                            format="yyyy-MM-dd hh:mm:ss"
                            use12-hour
                            type="datetime"
                            v-model="blog.event_end">
                    <label for="startDate" slot="before"><i class="fas fa-calendar-alt"></i></label>
                  </datetime>
                  <span>{{ blog.event_end | moment("dddd, MMMM Do YYYY, h:mm:ss a") }}</span>
                </div>

                <div class="col-12">
                  <v-file-input
                      outlined dense
                      v-model="blog.social_share_image"
                      @change="checkSize($event)"
                      label="Social share image"
                      prepend-inner-icon="mdi-file"
                      prepend-icon=""
                  ></v-file-input>
                  <span class="text-danger" v-if="sizeExceed">Please upload a file upto 20MB</span>

                </div>
                <div class="col-12 mt-4" v-if="blog.social_share_image_path && blog.social_share_image_path['thumb']">
                  <img :src="blog.social_share_image_path['thumb']" alt/>
                </div>
                <div class="col-12">
                  <v-select
                      :items="albums"
                      item-value="id"
                      item-text="title"
                      v-model="blog.album_id"
                      dense
                      outlined
                      label="Select linked gallery"
                  >

                  </v-select>

                </div>
                <div class="col-12 mt-4">
                  Status
                  <v-switch
                      v-model="blog.is_active"
                      :label="blog.is_active?'Active':'Inactive'"
                      name="check-button"
                      class
                      switch
                  >
                  </v-switch>
                </div>

              </div>
              <div class="col-12">
                <div class="row">


                  <div class="col-12 mt-5 text-right">
                    <v-btn v-if="checkIsAccessible('blog', 'create')"
                        class="text-white mr-2 btn btn-primary"
                        depressed :disabled="sizeExceed"
                        @click="createOrUpdate"
                        :loading="isBusy"
                    >Save
                    </v-btn>
                    <v-btn
                        class="btn btn-standard  "
                        depressed
                        @click="redirectTo"
                    >Back
                    </v-btn>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <pre>{{blog}}</pre> -->
    </div>
  </v-app>
</template>
<script>
import {required} from "vuelidate/lib/validators";
import BlogService from "@/core/services/blog/BlogService";
import BlogCategoryService from "@/core/services/blog/category/BlogCategoryService";
import UploadAdapter from "./UploadAdapter";
import AlbumService from "@/core/services/album/AlbumService";

const blogService = new BlogService();
const categoryService = new BlogCategoryService();
const albumService = new AlbumService();
import {Datetime} from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css'

export default {
  validations: {
    blog: {
      title: {required},
      publish_date: {required},
      content: {required},
      category_id: {required}
    }
  },
  components: {
    datetime: Datetime
  },
  data: vm => ({
    blog_types: [
      {value: "event", title: "Event"},
      {value: "blog", title: "Blog"},
      {value: "news", title: "News"},
    ],
    blog_content_types: [
      {value: "video", title: "Video Blog"},
      {value: "text", title: "Text Blog"},
    ],
    categories: [],
    albums: [],
    isBusy: false,
    sizeExceed: false,
    edit: false,
    menu2: false,
    menu3: false,
    blog_id: null,
    type: null,
    editorData: "<p>Content of the editor.</p>",
    editorConfig: {
      // extraPlugins: [this.uploader],
      versionCheck: false,
    },
    selected: [],
    blog: {
      title: null,
      publish_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      content: null,
      seo_title: null,
      seo_keyword: null,
      seo_description: null,
      social_share_description: null,
      category_id: null,
      type: "blog",
      blog_type: "text",
      is_active: true,
      event_start: '',
      event_end: '',
      author_name:null,
    }
  }),
  computed: {
    checkBlogType() {
      if (this.type == 'blog' && this.blog.blog_type == 'text') {
        return true;
      } else if (this.type != 'blog') {
        return true;
      } else
        return false;
    },


    event_startDate() {
      return this.$moment(this.blog.event_end).toISOString();
    },
    routeTab() {
      return {
        title: `Add ${this.capitalize(this.type)}`,
        tips: `Add ${this.capitalize(this.type)}`
      }
    },
  },
  watch: {
    $route(to, from) {
      let type = this.$route.params.type;
      this.blog.type = type;
    },

  },
  mounted() {
    this.type = this.$route.params.type;
    this.blog.type = this.type;
    this.getCategories();
    this.getAlbums();
    this.blog_id = this.$route.params.id;
    if (this.blog_id) {
      this.edit = true;
      this.getBlog();
    }
  },
  methods: {

    capitalize(string) {
      if (string && string != undefined && string != null) {
        string = string.charAt(0).toUpperCase() + string.slice(1);
        return string;
      }
    },
    uploader(editor) {
      editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
        return new UploadAdapter(loader);
      };
    },
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${year}-${month}-${day}`
    },
    formatDatePickerWise(date) {
      if (!date) return null
      const [day, month, year] = date.split('/')
      return `${year}-${month}-${day}`
    },
    todayDate() {
      var today = new Date();
      var dd = today.getDate();

      var mm = today.getMonth() + 1;
      var yyyy = today.getFullYear();
      if (dd < 10) {
        dd = '0' + dd;
      }

      if (mm < 10) {
        mm = '0' + mm;
      }
      today = yyyy + '-' + mm + '-' + dd;

      return today;
    },
    checkSize(file) {
      let size = file.size / 1024 / 1024;
      let mbSize = size.toFixed(2);
      if (mbSize > 20) {
        this.sizeExceed = true;
      } else {
        this.sizeExceed = false;
      }
    },
    getCategories() {
      categoryService
          .paginate(this.blog.type)
          .then(response => {
            this.categories = response.data.data;
          })
          .catch(error => {
            // console.log(error);
          });
    },
    getAlbums() {
      albumService
          .allActive()
          .then(response => {
            this.albums = response.data;
          })
          .catch(error => {
            // console.log(error);
          });
    },
    buildSelected() {
      let ids = this.blog.category_id;
      if (ids && ids.length > 0) {
        ids.forEach(ele => {
          this.selected.push(parseInt(ele));
        });
      }
    },
    getBlog() {
      blogService
          .show(this.blog_id)
          .then(response => {
            this.blog = response.data.blog;
            if (this.blog.blog_type == null || this.blog.blog_type == "null")
              this.blog.blog_type = 'text'
            if (this.blog.video_url == null || this.blog.video_url == "null")
              this.blog.video_url = ''

          /*  this.blog.publish_date = this.formatDatePickerWise(this.blog.publish_date)*/
            if (this.blog.type == 'event') {
              if (this.blog.event_start)
                this.blog.event_start = this.$moment(this.blog.event_start).toISOString();
              if (this.blog.event_end)
                this.blog.event_end = this.$moment(this.blog.event_end).toISOString();
            }
            delete this.blog["social_share_image"];
            delete this.blog["author_image"];
            this.buildSelected();
          })
          .catch(error => {
            // console.log(error);
          });
    },
    generateFd() {
      // if (this.blog.type=='event'){
      //     this.blog.event_start =  this.$moment(this.blog.event_start).utc().format('YYYY-MM-DD HH:mm:ss a')
      //     this.blog.event_end =  this.$moment(this.blog.event_end).utc().format('YYYY-MM-DD HH:mm:ss a')
      // }
      let fd = new FormData();
      for (let key in this.blog) {
        if (
            key == "social_share_image" &&
            this.blog["social_share_image"] &&
            this.blog["social_share_image"] != null
        ) {
          fd.append("social_share_image", this.blog[key]);
        } else if (
            key == "author_image" &&
            this.blog["author_image"] &&
            this.blog["author_image"] != null
        ) {
          fd.append("author_image", this.blog[key]);
        } else if (key == "is_active") {
          fd.append("is_active", this.blog.is_active ? 1 : 0);
        } else {
          fd.append(key, this.blog[key]);
        }
      }
      return fd;
    },
    createOrUpdate() {
      this.blog.category_id = this.selected;
      this.blog.album_id == null ? delete this.blog.album_id : '';
      this.$v.blog.$touch();
      if (this.$v.blog.$error) {
        setTimeout(() => {
          this.$v.blog.$reset();
        }, 3000);
      } else {
        if (this.blog.type != 'event')
          delete this.blog.event_date;
        let fd = this.generateFd();
        if (this.edit) {
          this.updateBlog(fd);
        } else {
          this.createBlog(fd);
        }
      }
    },
    updateBlog(fd) {
      this.isBusy = true;


      blogService
          .update(this.blog.id, fd)
          .then(response => {
            this.isBusy = false;
            this.$snotify.success("Information updated");
            this.$tabs.close().then(response => {
              this.$router.push({
                name: "blogs-list",
                params: {type: this.blog.type}
              });
            });
          })
          .catch(error => {
            this.isBusy = false;
            this.$snotify.error("Something Went Wrong");
            // console.log(error);
          });
    },
    createBlog(fd) {
      this.isBusy = true;
      blogService
          .store(fd)
          .then(response => {
            this.isBusy = false;
            this.$snotify.success("Information added");
            this.$tabs.close().then(response => {
              this.$router.push({
                name: "blogs-list",
                params: {type: this.blog.type}
              });
            });
          })
          .catch(error => {
            this.isBusy = false;
            this.$snotify.error("Something Went Wrong");
            // console.log(error);
          });
    },
    redirectTo() {
      this.$router.push({
        name: "blogs-list",
        params: {type: this.blog.type}
      });
    }
  }
};
</script>

<style scoped>
  .v-text-field__details {
    display: inline-block !important;
  }
</style>
